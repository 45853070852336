.Checklist {
	max-width: MIN(80vw, 900px);
	margin: 0 auto;
	padding: 2rem;
}

.Checklist > input {
	font-size: 3em;
	font-weight: lighter;
	border: none;
	width: 100%;
	text-align: center;
	padding: 0.5em;
}

@media screen and (max-width: 768px) {
	.Checklist {
		padding: 2rem 0;
		max-width: calc(100% - 2rem);
	}
	.Checklist > input {
		font-size: 2.2em;
	}
}

.Checklist > input:focus {
	outline: none;
	text-decoration: 3px underline pink;
	text-underline-offset: 0.5rem;
}

.List {
	border-radius: 0.5rem;
	overflow: hidden;
}

.ListItem {
	display: grid;
	grid-template-columns: 1.3rem 1fr 1.3rem;
	padding: 0.5rem 1rem;
}

.ListItem > input[type='checkbox'] {
	cursor: pointer;
	transform: scale(1.4);
}

.ListItem > input[type='text'] {
	text-align: left;
	padding: 0.5rem;
	font-size: 1.5rem;
	margin: 0 0.5rem;
	background-color: transparent;
	border: none;
	width: 100%;
}

.ListItem > input[type='text']:focus {
	outline: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	padding-bottom: 2px;
	margin-bottom: 5px;
}

.ListItem > button {
	background-color: transparent;
	border: none;
	font-size: 1.1rem;
	opacity: 0;
	transition: opacity 0.15s;
	cursor: pointer;
	user-select: none;
}

.ListItem:hover > button {
	opacity: 1;
}

.checkedOff {
	text-decoration: line-through;
	color: rgba(0, 0, 0, 0.3);
}

.NewItemEntry {
	display: grid;
	grid-template-columns: 1fr 3rem;
	padding: 0.5rem;
}

.NewItemEntry > button {
	background-color: pink;
	border: none;
	cursor: pointer;
	border-radius: 50%;
	width: 2.2rem;
	height: 2.2rem;
	font-size: 1.2rem;
	justify-self: center;
	align-self: center;
	text-align: center;
	transition: transform 0.15s;
}

.NewItemEntry > button:hover {
	transform: scale(1.1);
}

.NewItemEntry > input {
	font-size: 1.5rem;
	padding: 0.5rem 1rem;
	display: block;
	width: 100%;
	border: none;
	border-bottom: 2px solid #ccc;
}

.NewItemEntry > input:focus {
	outline: none;
}
